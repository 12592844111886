import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import scheduleIcon from "../../assets/icons/news_read_more.png"

const ActivityItem = ({ title, text, schedule, image, button_text }) => {
  const hasSchedule = !!schedule.url

  return (
    <Container>
      <Content>
        <Title>{title.text}</Title>
        <Text dangerouslySetInnerHTML={{ __html: text.html }} />

        {hasSchedule ? (
          <ButtonContainer>
            <Button
              href={schedule.url}
              target="_blank"
              rel="noreferrer noopener"
              aria-label="schedule button"
            >
              {button_text.text}
              <ScheduleIcon src={scheduleIcon} alt="schedule icon" />
            </Button>
          </ButtonContainer>
        ) : null}
      </Content>
      <ImageContainer>
        <Img
          fluid={image.fluid}
          alt="activity"
          imgStyle={{
            objectFit: "contain",
            minWidth: "360px",
          }}
        />
      </ImageContainer>
    </Container>
  )
}

export default ActivityItem

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
    // reverse column order at even rows
    &:nth-child(even) {
      // right content padding
      > div:nth-of-type(1) {
        padding: 0 40px;
      }
      div:nth-of-type(2) {
        grid-column-start: 1;
        grid-row: 1;
      }
      div:nth-of-type(1) {
        grid-column-start: 2;
        grid-row: 1;
      }
    }
    &:nth-child(odd) {
      // left content padding
      > div:nth-of-type(1) {
        padding: 0 40px 0 0;
      }
    }
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Title = styled.h2`
  font-family: "Moderat Bold", sans-serif;
  font-size: 1.5rem;
  color: var(--primary-text);
`

const Text = styled.div`
  p {
    color: var(--primary-text);
    font-family: "Moderat Regular", sans-serif;
    line-height: 1.5rem;
    padding: 0;
    margin-bottom: 1rem;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 0;
`

const Button = styled.a`
  color: var(--primary-text);
  text-decoration: none;
  font-family: "Moderat Bold", sans-serif;
  text-transform: capitalize;
`

const ScheduleIcon = styled.img`
  height: min-content;
  padding: 0 8px;
`

const ImageContainer = styled.div`
  padding: 0 0 24px 0;
  .gatsby-image-wrapper {
    height: 400px;
  }
  @media (max-width: 1000px) {
    grid-row-start: 1;
    .gatsby-image-wrapper {
      height: unset;
    }
  }
`
