import React from "react"
import styled from "styled-components"
import Layout from "../components/layout/layout"
import {
  getActivitiesPageData,
  useActivitiesPageData,
} from "../hooks/useActivitiesPageData"
import PageHeader from "../components/pageHeader"
import SEO from "../components/seo"
import ActivityItem from "../components/activities/activityItem"
import { withPreview } from "gatsby-source-prismic"

const Activities = ({ data: previewData }) => {
  const queryData = useActivitiesPageData()
  const { uid, heading, activities, bookData } = getActivitiesPageData(
    queryData,
    previewData
  )
  return (
    <Layout bookBannerData={bookData}>
      <SEO title={uid} />
      <PageHeader {...heading} />
      <Container>
        {activities.map((activity, i) => (
          <ActivityItem key={i} {...activity} />
        ))}
      </Container>
    </Layout>
  )
}

export default withPreview(Activities)

const Container = styled.div`
  width: 80%;
  margin: 0 auto 80px auto;
  display: grid;
  grid-gap: 40px 0;
`
